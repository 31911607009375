import { tns } from "tiny-slider/src/tiny-slider";

if (document.querySelector('#productSlider')) {
    let productSlider = tns({
        container: '#productSlider',
        items: 1,
        speed: 600,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        autoplayButtonOutput: false,
        prevButton: document.querySelector('.product__controllers.controllers_prev'),
        nextButton: document.querySelector('.product__controllers.controllers_next'),
        nav: true,
        navPosition : 'bottom',
        gutter: 30,
        responsive : {
            992 : {
                items : 3
            },
            768 : {
                items : 2
            },
        }
    });
    productSlider.play();


    $(document).on('click', '.product__filter-btn', function () {
        productSlider.destroy()

        const value = $(this).text()

        $('.product-card').parent().prependTo('.product__wrapper-hidden')
        $('.product-card[data-product="' + value + '"]').parent().prependTo('#productSlider')
        productSlider = productSlider.rebuild()
    })
}

if (document.querySelector('#workSlider')) {
    let workSlider = tns({
        container: '#workSlider',
        items: 1,
        speed: 600,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        autoplayButtonOutput: false,
        prevButton: document.querySelector('.work__controllers.controllers_prev'),
        nextButton: document.querySelector('.work__controllers.controllers_next'),
        nav: true,
        navPosition : 'bottom',
        gutter: 30,
        responsive : {
            768 : {
                items : 2
            },
        }
    });
    workSlider.play();
}

if (document.querySelector('#certificateSlider')) {
    let certificateSlider = tns({
        container: '#certificateSlider',
        items: 1,
        speed: 600,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        autoplayButtonOutput: false,
        prevButton: document.querySelector('.certificate__controllers.controllers_prev'),
        nextButton: document.querySelector('.certificate__controllers.controllers_next'),
        nav: true,
        navPosition : 'bottom',
        gutter: 30,
        responsive : {
            992 : {
                items : 6
            },
            768 : {
                items : 3
            },
        }
    });
    certificateSlider.play();
}