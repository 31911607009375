if ($(document).ready()) {
    if (document.querySelector('#YaMaps')) {
        let YaMapsShown = false;

        $(window).scroll(function () {
            if (!YaMapsShown) {
                if ($(window).scrollTop() + $(window).height() > $(document).height() - 1000) {
                    showYaMaps();
                    YaMapsShown = true;
                }
            }
        });

        function showYaMaps() {
            console.debug('maps loading')
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Abdf09117d1211e56c6c154b10e644ae75079aea583d84d7f5956e6deb8d02493&amp;width=100%25&amp;height=400&amp;lang=ru_RU&amp;scroll=true';
            document.getElementById("YaMaps").appendChild(script);
        }
    }
}
