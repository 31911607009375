document.querySelectorAll('form button').forEach((e) => {
    e.addEventListener('click', function () {
        const form = e.parentElement.id
        const el = document.forms[form]
        const er = el.querySelector('.text-error')

        let body = new FormData(el)

        const url = '/theme/Climat73/mail.php'

        el.preventDefault ? el.preventDefault() : el.returnValue = false;

        var req = new XMLHttpRequest();

        req.upload.onprogress = function() {
            el.querySelector('button').disabled = true
        }

        req.open('POST', url, true);
        req.onload = function () {
            if (req.status >= 200 && req.status < 400) {
                const data = this.response
                if (data === 'Готово') {
                    er.style.display = "block"
                    er.style.color = '#48AF4C'
                    er.textContent = 'Ваше сообщение успешно отправлено!'
                    el.querySelector('button').disabled = true
                    setTimeout(function(){
                        er.style.display = "none"
                        el.querySelector('button').disabled = false
                    },3000);
                } else {
                    er.style.display = "block"
                    er.style.color = '#F54940'
                    er.textContent = data
                    el.querySelector('button').disabled = true
                    setTimeout(function(){
                        er.style.display = "none"
                        el.querySelector('button').disabled = false
                    },3000);
                }
            }
        }

        req.send(body);

    })
})