import '../scss/index.scss';
import $ from 'jquery';
import 'remodal';
import Inputmask from "inputmask"
import { tns } from "tiny-slider";

global.jQuery = global.$ = $;

require('./_main');
require('./_yaMap');
// require('./_mainMenu')
// require('./_countup')
// require('./_benefit')
require('./_inputMask')
require('./_mail')
// require('./_pallet')
require('./_tiny-slider')