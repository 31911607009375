$('.ask__header').on('click', function () {
    $(this).parent().toggleClass('ask__item_active')
})

$("body").on('click', '[href*="#"]', function (e) {
    var fixed_offset = 10;
    $('html,body').stop().animate({scrollTop: $(this.hash).offset().top - fixed_offset}, 1000);
    e.preventDefault();
});

if (window.innerWidth < 992) {
    $('.menu__wrapper').appendTo('.header__wrapper-mob')
    $('.header__phone-wrapper').appendTo('.header__wrapper-mob')
    $('.header__wrapper .social__wrapper').appendTo('.header__wrapper-mob')

    $('.header__burger').on('click', function () {
        $('.header__wrapper-mob').toggleClass('header__wrapper-mob_show')
    })
}

let filters = new Map()

document.querySelectorAll('.product-card').forEach((e)=> {
    filters.set(e.dataset.product, e.dataset.product)
})

for (let key of filters.keys()) {
    $('.product__filter').append('<button class="product__filter-btn">' + key + '</button>')
}
